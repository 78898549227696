import React from 'react'
import WoodsideLogo from '../images/WoodSideRecordsSmall2.png'
import { Link } from 'react-router-dom'

export default function NavBar() {
    const navImageRef = React.useRef(null)
    const scrollValue = React.useRef(0)

    function getScrollValue() {
        return -window.scrollY;
    }

    // if window size is less than 768px then don't do this
    

    window.addEventListener('scroll', function(e) {
        scrollValue.current = getScrollValue();
    })

    setInterval(() => {
        navImageRef.current.style.transform = `rotate(${Math.abs(scrollValue.current)}deg)`
    }, 50)


  return (
    <nav class="navbar bg-dark fixed-top">
    <div class="container-fluid">
        <Link class="navbar-brand text-light" to="/">
            <img ref={navImageRef} src={WoodsideLogo} width="50" class="d-inline-block woodside-nav-logo mx-2" alt="Woodside Records Logo"></img>
            <h1 class="d-inline-block fs-5">Woodside Records</h1>
        </Link>
        
        <Link to="/contact" class="btn btn-outline-light">Contact</Link>
    </div>
</nav>
    )
}
