import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

export default function Contact() {
  return (
    <>
    <NavBar />
    <div class="container pt-5">
        <div class="row pt-2">
            <div class="col-12">
                <h1 class="text-center mt-5">Contact Us</h1>
                <p class="text-center">Please get in touch if you have any queries or want to make some music.</p>
                <p class="text-center">We'd love to hear from you!</p>
                <p class="text-center">Find us <a href="">@woodside_records</a> on instagram or email us at <a href="mailto:woodsiderecordsco@gmail.com">woodsiderecordsco@gmail.com</a></p>
                <p class="text-center">Or fill out the form below!</p>
            </div>
        </div>
        <div class="row card m-1 p-1 py-3 mb-5">
            <div class="col-12">
                <form action="https://formsubmit.co/woodsiderecordsco@gmail.com" method="POST" class="mw-25">
                    <input type="hidden" name="_captcha" value="false" />
                    
                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input required type="text" name="name" class="form-control" id="name"></input>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input required type="email" name="email" class="form-control" id="email"></input>
                    </div>
                    <div class="mb-3">
                        <label for="message" class="form-label">Message</label>
                        <textarea required class="form-control" name="message" id="message" rows="3"></textarea>
                    </div>
                    <button type="submit" class="btn btn-dark w-100">Submit</button>
                </form>
            </div>
            
        </div>
        <a href="https://woodsiderecords.substack.com/" class="btn btn-outline-dark w-100 mb-2">Join our newsletter</a>
    </div>
    </>
  )
}
