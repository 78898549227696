import React from 'react'

export default function Footer() {
  return (
   <footer>
         <div class="container-fluid bg-dark text-light">
                <div class="row py-3 text-center">
                    
                    <ul className="list-unstyled">
                        <li><a href="mailto:woodsiderecordsco@gmail.com" class="text-light">woodsiderecordsco@gmail.com</a></li>
                        <li><a href="https://www.instagram.com/woodside_records/" class="text-light">@woodside_records</a></li>
                    </ul>
                </div>

            </div>
        
   </footer>
  )
}
