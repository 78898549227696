import React from 'react'

export default function MainHero() {
  return (
    <div class="text-center py-5 mt-5">
        <h1 class="mt-3">Woodside Records</h1>
        <p>Aberdeen, Scotland</p>
        <a href="https://woodsiderecords.substack.com/" class="btn btn-outline-dark">Join our Newsletter</a>
    </div>
  )
}
