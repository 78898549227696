import React from 'react'

export default function MainBandCard({url,artist,description,cta}) {
  return (
    <div class="main-band-card-square border d-flex flex-column align justify-content-around mx-auto">
        <img src={url} class="main-band-card-img mx-auto" alt="Artist"></img>
        <div class="text-center main-band-card-inner">
            <h3>{artist}</h3>
            <p>{description}</p>
            <button class="btn btn-outline-light">{cta}</button>
        </div>
    </div>
  )
}
