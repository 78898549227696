import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import MainBandCard from './components/MainBandCard';
import MainHero from './components/MainHero';

// Artists
import JohnAllison from './images/johnallison.jpg'
import Footer from './components/Footer';


function App() {
  return (
    <>
      <NavBar />
      <MainHero />
      
      <MainBandCard url={JohnAllison} artist={'John Allison'} description={'Whiskey Prescription'} cta={'Coming Soon...'}/>
      
      <div class="my-4 py-5"></div>
      <Footer />
    </>
  );
}

export default App;
